import React from 'react';
import video from './img/island.mp4';

const VideoBackground = () => {
  return (
    <div className="video-background">
      <video autoPlay loop muted>
        <source src={video} type="video/mp4" />
        {/* Add additional video sources for different formats if needed */}
      </video>
    </div>
  );
};

export default VideoBackground;