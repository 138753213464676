import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase';
import { collection, getDocs, doc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import downloading from './img/downloading.png'
import Popup from './Popup';
import { saveAs } from 'file-saver';
import firelogo from './img/fire_logo.png'
import shelllogo from './img/shell_logo.png'



function Inscriptions() {

    /*
        Shellz -> 0
        Lepidoz -> 1
    */
    const [selectedCollection, setSetectedCollection] = useState(0);
    const [shellzItems, setShellzItems] = useState(null);
    const [lepidozItems, setLepidozItems] = useState(null);
    const [selectedInscription, setSelectedInscription] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [metadata, setMetadata] = useState(null);

    const onClickInstription = async (id, token, url) => {
        setSelectedInscription({ id: id, token: token, imageUrl: url});

        const path = selectedCollection == 0 ? "metadata" : "Lepidoz_metadata";
        const metadataUrl = await getDownloadURL(ref(storage, `${path}/${token}.json`));
        const response = await fetch(metadataUrl);
        if (response.status !== 200) return;
        const json = await response.json();
        setMetadata(json);
        setShowPopup(true);

    }

    const renderCards = () => {
        const items = selectedCollection == 0 ? shellzItems : lepidozItems;
        if (!items) return null;
        console.log(items);
        return (
            <ul className="cards">
                {items.map((item) => {
                    return <Card key={item.id} id={item.id} token={item.token} selectedCollection={selectedCollection} onClick={onClickInstription} />;
                })}
            </ul>
        )
    }

    const collectionChooseButtons = () => {
        return(
            <div className='collection-selection'>
                <button className={`collection-selection-button ${selectedCollection === 1 ? 'grey-image' : ''}`} onClick={onClickShellz}>
                    <img src={shelllogo}/>
                </button>
                <button className={`collection-selection-button ${selectedCollection === 0 ? 'grey-image' : ''}`} onClick={onClickLepidoz}>
                    <img src={firelogo}/>
                </button>
            </div>
        );
    }

    const onClickShellz = () => {
        setSetectedCollection(0);
        fetchAllInstriptions();
    }

    const onClickLepidoz = () => {
        setSetectedCollection(1);
        fetchAllInstriptions();
    }

    const onCloseMintSuccessPopup = () => {
        setSelectedInscription(null);
        setShowPopup(false);
    }

    const onOrdinals = () => {
        const url = `https://ordinals.com/inscription/${selectedInscription.id}`;
  
        // open a new tab with the URL
        window.open(url, '_blank');
    }

    const onMarketplace = () => {
        const url = `https://magiceden.io/ordinals/item-details/${selectedInscription.id}`;
  
        // open a new tab with the URL
        window.open(url, '_blank');
    }



    const traitComponent = () => {
        if (!metadata) return
        return (<div className="detail-items-list">
            {metadata.attributes.filter(item => item.value !== 'None').map((item, index) => (
                <div className="detail-item" key={index}>
                    <div className='detail-item-value-container'>
                        <div className="detail-key">{item.trait_type}</div>
                        <div className="detail-value">{item.value}</div>
                    </div>
                </div>
            ))}
        </div>)
    }

    const onDownloadPFP = () => {
        dowloadHighRes(selectedInscription.token);
    }

    const renderPopup = () => {

        if (!showPopup || !selectedInscription || !metadata instanceof Promise) return null;

        console.log(selectedInscription.imageUrl);
        
        return (
            <Popup
                text={metadata.name}
                closePopup={onCloseMintSuccessPopup}
                action={onOrdinals}
                marketplaceAction={onMarketplace}
            >
                <div className="popup-container">
                    <h1>{metadata.name}</h1>
                    <span className='popup-rank'>Rank {metadata.rank}</span>
                    <div className="detail-container">
                        <div className="detail-image-container">
                            <img src={selectedInscription.imageUrl}/>
                            <div className="download-btn" onClick={onDownloadPFP}>
                                <span>PFP</span>
                                <img src={downloading}/>
                            </div>
                        </div>
                        
                        <div className='trait_container'>
                        <h1>Traits</h1>
                        {traitComponent()}
                    </div>
                    
                    </div>
                    
                </div>
            </Popup>
        );
    }

    const dowloadHighRes = (tokenID) => {
        const imgurl = selectedCollection == 0 ? `heighres/${tokenID}.png` :  `Lepidoz_heighres/${tokenID}.png`; 
    
        console.log(imgurl);
        getDownloadURL(ref(storage, imgurl))
            .then((url) => {
                fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        try {
                            saveAs(blob, `${tokenID}.png`);
                        } catch (error) {
                            // if the saveAs method is not supported by the browser, open the image in a new tab
                            window.open(url);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchAllInstriptions = async () => {

        if(selectedCollection == 0 && (shellzItems == null || shellzItems.length === 0)) {
            const queryRef = collection(db, 'SatoshiShellz');
            const querySnapshot = await getDocs(queryRef);
            const docs = querySnapshot.docs.map(doc => {
                return { id: doc.id, token: doc.data().token }
            });
            setShellzItems(docs);
        } else if (selectedCollection == 0 && (lepidozItems == null || lepidozItems.length === 0)) {
            const queryRef = collection(db, 'SatoshiLepidoz');
            const querySnapshot = await getDocs(queryRef);
            const docs = querySnapshot.docs.map(doc => {
                return { id: doc.id, token: doc.data().token }
            });
            setLepidozItems(docs);
        }
        
    }

    useEffect(() => {
        fetchAllInstriptions();
    }, [])

    return (
        <div className="inscriptions">
            {collectionChooseButtons()}
            {renderCards()}
            {renderPopup()}
        </div>
    );
}

export default Inscriptions;


function Card(props) {
    const [url, setUrl] = useState('');

    useEffect(() => {
        let path = props.selectedCollection == 0 ? `images-nobg/${props.token}.png` : `Lepidoz_images-nobg/${props.token}.png`
        getDownloadURL(ref(storage, path)).then((url) => {
            setUrl(url);
        });
    }, [props.token]);


    return <li className="cards_item">
        <div className="card">

            <img src={url} onClick={() => props.onClick(props.id, props.token, url)} />
            <span>#{props.token}</span>
        </div>
    </li>;
}