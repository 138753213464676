import img17 from './img/plam-tree-1-HD.png';
import img32 from './img/32.png';
import img36 from './img/36.png';
import palms from './img/plam-tree-2-HD.png';



function Home() {
    return (
        <div className='home'>
            <div className='image-section'>

                <img className='no-mobile animate__animated animate__fadeIn animate__slow' src={img32} />
                <img className='no-mobile animate__animated animate__fadeIn animate__slow mirrored-image' src={img36} />
            </div>
        </div>
    );
}

export default Home;