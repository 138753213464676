import { useRef } from "react";
// import logo from './img/logo.png'
import twitter from './img/twitter.png'
import discord from './img/discord.png'
import { TypeAnimation } from 'react-type-animation';
import logo from './img/logo.png';
import BackgroundMusic from './bgMusic';

function Navbar() {

    const navRef = useRef();


    return (
        <header>
             <BackgroundMusic />
            <div className='img-logo'>
                {/* <span className="header-title">Satoshi Shellz</span> */}
                <a href='/'>
                    <img src={logo} />
                </a>
               
            </div>

            <nav ref={navRef}>
                <a className="animate__animated animate__fadeIn animate__slow animate__delay-1s" href="/inscriptions">GALLERY</a>

                <div className="socials">
                    <a className='animate__animated animate__fadeIn animate__fast animate__delay-1s' href="https://twitter.com/satoshishellz" target="_blank">
                        <img src={twitter} alt='Twitter Link'></img>
                    </a>
                    <a className='animate__animated animate__fadeIn animate__fast animate__delay-1s' href="https://discord.gg/M3GaVsNBkG" target="_blank">
                        <img src={discord} alt='Discord Link'></img>
                    </a>
                </div>
            </nav>
            <div className='nav-btn-container'>
                {/* <img src={burgerMenu}/> */}
            </div>

        </header>
    );
}

export default Navbar;