import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Inscriptions from './Inscriptions';
import 'animate.css';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import VideoBackground from './video';

function App() {
  return (
    <div className="app">
      <VideoBackground />
      <div className='main'>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/inscriptions" element={<Inscriptions />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
