import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import music from './img/music.mp3'
import playImage from './img/play-button.png' 
import pauseImage from './img/pause.png' 

function BackgroundMusic() {
  const audioRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);

  const play = () => {
    isPaused ? audioRef.current.play() : audioRef.current.pause();
    setIsPaused(!isPaused);
};

  return (
    <>
    <div className='music-player-component'>
    <audio src={music} loop ref={audioRef}>
            Your browser does not support the audio element.
        </audio>
        <img onClick={play} src={isPaused ? playImage: pauseImage}/>
    </div>
        {/* <button onClick={forward}>Forward</button>
        <button onClick={backward}>Backward</button> */}
    </>
);
}

export default BackgroundMusic;
