import React from 'react'
import { Button } from 'react-bootstrap';
import ordinalsLogog from './img/ordinalslogo.png'
import magicedenlogo from './img/Magic_Eden_Logo.png'

class Popup extends React.Component {
    render() {
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    {this.props.children}
                    <div className='popup_footer'>
                    <Button size="lg" className="custom-btn" onClick={this.props.closePopup}>Close</Button>
                    <div >
                        <button className='popup_link_button' onClick={this.props.action}>
                            <img src={ordinalsLogog}/>
                        </button>
                        <button className='popup_link_button' onClick={this.props.marketplaceAction}>
                            <img src={magicedenlogo} />
                        </button>
                    </div>
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;